import React, { useEffect, useState } from "react"
import Wrapper from "~/components/reusable/Wrapper"
import Spacer from "~/components/reusable/svgs/Spacer"
import Page from "~/components/reusable/Page"
import Button, { ButtonLink } from "~/components/reusable/Button"
import { gql, useMutation } from "@apollo/client"
import { navigate } from "gatsby"
import Layout from "~/components/Layout"
import { isBrowser } from "~/utils/helpers"

const RESET_PASSWORD = gql`
  mutation resetUserPassword(
    $key: String!
    $login: String!
    $password: String!
  ) {
    resetUserPassword(
      input: { key: $key, login: $login, password: $password }
    ) {
      user {
        databaseId
      }
    }
  }
`

export default function SetPassword() {
  if (!isBrowser()) {
    return null
  }
  const [resetKey, setResetKey] = useState("")
  const [login, setLogin] = useState("")
  const [hasResetKey, setHasResetKey] = useState<Boolean | null>(null)

  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")
  const [clientErrorMessage, setClientErrorMessage] = useState("")
  const [resetPassword, { data, loading, error }] = useMutation(RESET_PASSWORD)
  const wasPasswordReset = data
    ? Boolean(data?.resetUserPassword?.user?.databaseId)
    : false

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const isValid = validate()
    if (!isValid) return

    resetPassword({
      variables: {
        key: resetKey,
        login,
        password,
      },
    }).catch(error => {
      console.error(error)
    })
  }

  function validate() {
    setClientErrorMessage("")

    const isPasswordLongEnough = password.length >= 5
    if (!isPasswordLongEnough) {
      setClientErrorMessage("Password must be at least 5 characters.")
      return false
    }

    const doPasswordsMatch = password === passwordConfirm
    if (!doPasswordsMatch) {
      setClientErrorMessage("Passwords must match.")
      return false
    }

    return true
  }

  useEffect(() => {
    const params = new URLSearchParams(
      isBrowser() ? window.location.search : ""
    )
    const resetKey = params.get("key") || ""
    const login = params.get("login") || ""
    setResetKey(resetKey)
    setLogin(login)
    setHasResetKey(Boolean(resetKey && login))
  }, [])

  useEffect(() => {
    if (hasResetKey === false) {
      setClientErrorMessage("Invalid password reset link.")
      setTimeout(() => {
        navigate("/portal/login")
      }, 3000)
    }
  }, [hasResetKey])

  return (
    <Layout path="/set-password/">
      <Page title={`Set Password | Vetshare`}>
        <Wrapper className={``}>
          <div
            className={`dot absolute right-[-280px] top-[-280px] z-[-1] block h-[550px] w-[550px] rounded-full bg-theme-green md:h-[1058px] md:w-[1058px]`}
          />
          <div
            className={`login-form flex-set-0-0 z-[2] mx-auto mb-5 max-w-[545px] basis-almost-full rounded-[32px] bg-theme-yellow px-14 pt-10`}
          >
            {!wasPasswordReset ? (
              <>
                <h1 className={`mb-6 text-h3 font-bold md:text-h2`}>
                  Set Password
                </h1>
                <form method="post" onSubmit={handleSubmit} className="mb-8">
                  <fieldset disabled={loading} aria-busy={loading}>
                    <label
                      className="mb-2 block font-medium"
                      htmlFor="new-password"
                    >
                      Password
                    </label>
                    <input
                      className={`input rounded-4 vs-shadow-light mb-7 border-none p-5 text-caption text-theme-gray-mid`}
                      id="new-password"
                      type="password"
                      value={password}
                      autoComplete="new-password"
                      onChange={event => setPassword(event.target.value)}
                      required
                    />
                    <label
                      className="mb-2 block font-medium"
                      htmlFor="password-confirm"
                    >
                      Confirm Password
                    </label>
                    <input
                      className={`input rounded-4 vs-shadow-light mb-7 border-none p-5 text-caption text-theme-gray-mid`}
                      id="password-confirm"
                      type="password"
                      value={passwordConfirm}
                      autoComplete="new-password"
                      onChange={event => setPasswordConfirm(event.target.value)}
                      required
                    />
                    {clientErrorMessage ? (
                      <p className="error-message">{clientErrorMessage}</p>
                    ) : null}
                    {error ? (
                      <p className="error-message">{error.message}</p>
                    ) : null}
                    <Button
                      text={loading ? "Saving..." : "Save password"}
                      buttonColor="orange"
                      buttonStyle="primary"
                      type="submit"
                      disabled={loading}
                    />
                  </fieldset>
                </form>
              </>
            ) : (
              <div className="mb-8 flex flex-col space-y-8">
                <p className="text-h4">Your new password has been set.</p>
                <ButtonLink
                  link={"/portal/login"}
                  text={"Log in"}
                  buttonColor={"orange"}
                  className="!mx-auto"
                ></ButtonLink>
              </div>
            )}
          </div>
        </Wrapper>
        <Spacer top={`transparent`} bottom={`black`} />
      </Page>
    </Layout>
  )
}
